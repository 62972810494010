<ng-container *transloco="let t; scope: 'catalog'">
  <ng-container *ngIf="price().data as oPrice">
    <!-- user logged in-->
    <ng-container *ngIf="store.isLoggedIn(); else guestPrice">
      <ng-container
        *ngIf="
          oPrice.decBasePriceGross === null || (netto && oPrice.decAmountNetFC <= 0) || (!netto && oPrice.decBasePriceGross <= 0);
          else hasPrice
        "
      >
        <span>{{ t("catalog.article.price-is-zero") }}</span>
      </ng-container>
      <ng-template #hasPrice>
        <ng-container *ngIf="store.user()?.oCustomer?.bPrivateCustomer; else b2b">
          <span>
            <ng-container *ngIf="netto">{{ oPrice.decAmountNetFC | translocoCurrency }}</ng-container>
            <ng-container *ngIf="!netto">{{ oPrice.decBasePriceGross | roundSwissFranc | translocoCurrency }}</ng-container>
          </span>
          <span *ngIf="showUnit" class="text-sm"> / {{ oPrice.lngSalesPriceUnit }} {{ article().sCH1QuantityUnitSalesFL }}</span>
        </ng-container>

        <ng-template #b2b>
          <ng-container *ngIf="store.user() as user">
            <ng-container
              *ngIf="user.oCustomer.shtPL1EcommDefaultPrice === 0 || !user.oCustomer.shtPL1EcommDefaultPrice; else showStandard"
            >
              <span
                *ngIf="
                  showPromotion &&
                  oPrice.decPL1PromotionPrice &&
                  oPrice.decPL1PromotionPrice > 0 &&
                  oPrice.decPL1PromotionPrice < oPrice.decAmountNetFC
                "
                [ngClass]="
                  showPromotion && oPrice.decPL1PromotionPrice && oPrice.decPL1PromotionPrice > 0
                    ? 'text-sm line-through decoration-primary'
                    : ''
                "
              >
                {{ netto ? (oPrice.decAmountNetFC | translocoCurrency) : (oPrice.decBasePriceGross | round : 2 | translocoCurrency) }}
                <br />
              </span>
              <span
                [ngClass]="
                  showPromotion &&
                  oPrice.decPL1PromotionPrice &&
                  oPrice.decPL1PromotionPrice > 0 &&
                  oPrice.decPL1PromotionPrice < oPrice.decAmountNetFC
                    ? 'text-primary'
                    : ''
                "
              >
                {{
                  netto &&
                  oPrice.decPL1PromotionPrice &&
                  oPrice.decPL1PromotionPrice > 0 &&
                  oPrice.decPL1PromotionPrice < oPrice.decAmountNetFC
                    ? (oPrice.decPL1PromotionPrice! | translocoCurrency)
                    : netto
                    ? (oPrice.decAmountNetFC | translocoCurrency)
                    : (oPrice.decBasePriceNet | round : 2 | translocoCurrency)
                }}
              </span>
            </ng-container>
            <ng-template #showStandard>
              <span> {{ netto ? (oPrice.decAmountNetFC | translocoCurrency) : (oPrice.decAmountGrossFC | translocoCurrency) }}</span>
            </ng-template>

            <span *ngIf="showUnit" class="text-sm"> / {{ oPrice.lngSalesPriceUnit }} {{ article().sCH1QuantityUnitSalesFL }}</span>
          </ng-container>
        </ng-template>
      </ng-template>
    </ng-container>

    <ng-template #guestPrice>
      <ng-container *ngIf="oPrice.decBasePriceGross === null || oPrice.decBasePriceGross <= 0; else hasPrice">
        <span>{{ t("catalog.article.price-is-zero") }}</span>
      </ng-container>

      <ng-template #hasPrice>
        <span>
          <span> {{ netto ? (oPrice.decAmountNetFC | translocoCurrency) : (oPrice.decAmountGrossFC | translocoCurrency) }}</span>
        </span>
        <span *ngIf="showUnit" class="text-sm"> / {{ oPrice.lngSalesPriceUnit }} {{ article().sCH1QuantityUnitSalesFL }}</span>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>
