import { ChangeDetectionStrategy, Component, effect, EventEmitter, inject, Input, input, Output } from '@angular/core';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { RoundPipe } from '../../pipes/round.pipe';
import { Article } from '@lobos/common-v3';
import { ArticleService } from '../../services/catalog/article.service';
import { derivedAsync } from 'ngxtension/derived-async';
import { createPendingObserverResult } from '@ngneat/query';
import { GlobalStore } from '../../store/global.store';
import { SuedoPrice } from '../../interfaces/suedo-price.interface';
import { RoundSwissFrancPipe } from '../../pipes/round-swiss-franc.pipe';

@Component({
  selector: 'app-article-price',
  templateUrl: './article-price.component.html',
  styleUrls: ['./article-price.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgIf, NgClass, AsyncPipe, TranslocoLocaleModule, RoundPipe, RoundSwissFrancPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePriceComponent {
  article = input.required<Article>();
  @Input() showUnit: boolean = false;
  @Input() netto: boolean = true;
  @Input() showPromotion: boolean = false;
  @Output() zeroPrice: EventEmitter<boolean> = new EventEmitter<boolean>();

  articleService = inject(ArticleService);
  store = inject(GlobalStore);

  price = derivedAsync(
    () =>
      this.articleService.getPrice({
        articleId: this.article().sArticleID as string,
        currency: 'CHF',
        factor1: 1,
        factor2: 1,
        factor3: 1,
        qty: this.article().lngSalesPriceUnit,
        unit: this.article().sQuantityUnitSales,
      }).result$,
    {
      initialValue: createPendingObserverResult<SuedoPrice>(),
    },
  );

  constructor() {
    effect(() => {
      if (this.price().data && ((this.price().data!.decAmountNetFC <= 0) || (this.price().data!.decBasePriceGross <= 0))) {
        this.zeroPrice.emit(true);
      } else {
        this.zeroPrice.emit(false);
      }
    });
  }
}
